<script setup lang="ts">
const props = defineProps({
  errorDetail: String,
});

const broadcastModule = useBroadcastModule();

function close() {
  broadcastModule.close();
}
</script>

<template>
  <v-card light max-width="400">
    <v-card-title>{{ $t("components.broadcastTypes.errorDetails.title") }}</v-card-title>
    <v-card-text>
      {{ errorDetail }}
    </v-card-text>
    <v-card-actions>
      <v-row no-gutters justify="end">
        <v-btn plain @click="close">
          {{ $t("components.broadcastTypes.errorDetails.closeBtn") }}
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<style scoped></style>

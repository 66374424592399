<script setup lang="ts">
const authModule = useAuthModule();
const { t } = useI18n();
const router = useRouter();
const open = ref(false);
const { authenticated, loggedInUser, token } = storeToRefs(authModule);
function close() {
  open.value = false;
}

function logout() {
  token.value = undefined;
  authenticated.value = false;
  loggedInUser.value = "";
  router.push("/login");
}
</script>

<template>
  <v-dialog
    v-model="open"
    max-width="350"
  >
    <template #activator="{ props }">
      <slot
        name="activator"
        :props="props"
      />
    </template>

    <v-card>
      <v-card-title>{{ t("components.logoutDialog.title") }}</v-card-title>
      <v-card-text>{{ t("components.logoutDialog.subtitle") }}</v-card-text>
      <v-card-actions>
        <v-row
          no-gutters
          justify="end"
        >
          <AnybillButton
            id="btn-abort-logout"
            class="mr-2"
            text
            @click="close"
          >
            {{ t("components.logoutDialog.abortBtn") }}
          </AnybillButton>
          <AnybillButton
            id="btn-confirm-logout"
            @click="logout"
          >
            {{ t("components.logoutDialog.logoutBtn") }}
          </AnybillButton>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped></style>

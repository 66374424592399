<script setup lang="ts">
import LogoutDialog from "./dialogs/LogoutDialog.vue";

const { t } = useI18n();

const showMobileMenu = ref(false);
const myProfileInactive = ref(false);

const footerItem = computed(() => ({
  title: t("components.logoutDialog.logoutBtn"),
  icon: "logout",
}));

const route = computed(() => useRoute());

onMounted(() => {
  myProfileInactive.value = useRoute().fullPath === "/settings/profile";
});

watch(route, (r: any) => {
  myProfileInactive.value = r.fullPath === "/settings/profile";
});

async function logout() {
  toggleMobileMenu();
  useAuthModule().logout();
  setTimeout(() => {
    if (process.browser)
      document.location.reload();
  }, 500);
}

function toggleMobileMenu() {
  showMobileMenu.value = !showMobileMenu.value;
}
</script>

<template>
  <div>
    <div>
      <LogoutDialog :logout="logout">
        <template #activator="{ props }">
          <navigation-item
            :item="footerItem"
            class="singleItem"
            v-bind="props"
          />
        </template>
      </LogoutDialog>
    </div>
  </div>
</template>

<style scoped>
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 0;
}
</style>

<script setup lang="ts">
import { BroadcastTypes } from "~/stores/broadcast";

const snackbarModule = useSnackBarModule();
const broadcastModule = useBroadcastModule();
const { t } = useI18n();
const {
  showSnack,
  snackText,
  snackIcon: icon,
  snackIsBanner: isBanner,
  snackDetail,
  snackColor,
} = storeToRefs(snackbarModule);

// TODO: why is this sliced??
// const slicedError = computed(() => error.value?.toString().length > 50 ? `${error.value.toString().slice(0, 50)}...` : error.value);
const hasDetail = computed(() => !!snackDetail.value);

const isOpen = computed({
  get: () => showSnack.value,
  set: val => showSnack.value = val,
});

function toggleSnack() {
  isOpen.value = !isOpen.value;
}

function toggleMore() {
  isOpen.value = false;
  broadcastModule.show({
    newType: BroadcastTypes.ERROR,
    newArgs: { error: snackDetail.value },
  });
}
</script>

<template>
  <div id="snackbar-wrapper">
    <template v-if="!isBanner">
      <v-snackbar
        v-model="isOpen"
        dark
        multi-line
      >
        <v-row
          no-gutters
          justify="space-between"
          align="center"
        >
          <v-icon class="mr-2">
            {{ `mdi-${icon}` }}
          </v-icon>
          <div v-html="snackText" />
        </v-row>
        <template #actions>
          <v-btn
            v-if="hasDetail"
            variant="text"
            @click="toggleMore"
          >
            {{ t("components.snackBar.more") }}
          </v-btn>
          <v-btn
            variant="text"
            @click="toggleSnack"
          >
            {{ t("components.snackBar.close") }}
          </v-btn>
        </template>
      </v-snackbar>
    </template>
    <template v-else>
      <v-banner
        v-model="isOpen"
        app
        top
      >
        <v-row>
          <v-icon :color="isBanner ? snackColor : ''">
            {{
              `mdi-${icon}`
            }}
          </v-icon>
          <div>{{ snackText }}</div>
        </v-row>
        <template #actions>
          <v-btn
            v-if="hasDetail"
            variant="text"
            class="tw-uppercase"
            @click="toggleMore"
          >
            {{ t("components.snackBar.more") }}
          </v-btn>
          <v-btn
            variant="text"
            class="tw-uppercase"
            @click="toggleSnack"
          >
            {{ t("components.snackBar.close") }}
          </v-btn>
        </template>
      </v-banner>
    </template>
  </div>
</template>

<script setup lang="ts">
import CropRequired from "./broadcastTypes/standalone/cropRequired.vue";
import OnboardingLinkSentSuccessMessage from "./broadcastTypes/standalone/OnboardingLinkSentSuccessMessage.vue";
import EntranceFlowSuccessMessage from "~/components/broadcastTypes/standalone/EntranceFlowSuccessMessage.vue";
import ErrorDetail from "~/components/broadcastTypes/standalone/errorDetail.vue";
import { BroadcastTypes } from "~/stores/broadcast";
import EntranceFlowPosDistriSuccessMessage from "~/components/broadcastTypes/standalone/EntranceFlowPosDistriSuccessMessage.vue";

const broadcastModule = useBroadcastModule();

const { isOpen, type, args } = storeToRefs(broadcastModule);

const types = computed(() => BroadcastTypes);

const detailedError = computed(() => args.value.error ?? "");
</script>

<template>
  <v-overlay v-model="isOpen" z-index="6">
    <EntranceFlowSuccessMessage
      v-if="type === types.ENTRANCE_FLOW_SUCCESS"
    />
    <EntranceFlowPosDistriSuccessMessage
      v-if="type === types.ENTRANCE_FLOW_POS_DISTRI_SUCCESS"
    />
    <ErrorDetail v-if="type === types.ERROR" :error-detail="detailedError" />
    <CropRequired v-if="type === types.CROP_REQUIRED" />
    <OnboardingLinkSentSuccessMessage
      v-if="type === types.ONBOARDING_LINK_SUCCESS"
    />
  </v-overlay>
</template>

<style scoped>
.ps {
  max-height: 80vh;
}
</style>

<script setup lang="ts">
const broadcastModule = useBroadcastModule();

const tutorialLink = "https://anybill.freshdesk.com/support/solutions/80000459085";

function close() {
  broadcastModule.close();
}
</script>

<template>
  <v-card id="card-onboarding-success" light max-width="400">
    <v-card-title class="font-weight-bold">
      {{ $t("components.broadcastTypes.entranceFlowPosDistriSuccess.title") }}
    </v-card-title>
    <v-card-text>
      {{ $t("components.broadcastTypes.entranceFlowPosDistriSuccess.descriptionPartOne") }} <br>
      <b>{{ $t("components.broadcastTypes.entranceFlowPosDistriSuccess.descriptionImportant") }}</b> {{ $t("components.broadcastTypes.entranceFlowPosDistriSuccess.descriptionPartTwo") }}
      <a href="/customer-management" @click="close">{{ $t("components.broadcastTypes.entranceFlowPosDistriSuccess.descriptionPortal") }}</a>
      {{ $t("components.broadcastTypes.entranceFlowPosDistriSuccess.descriptionPartThree") }}
      <a :href="tutorialLink" target="_blank">{{ $t("components.broadcastTypes.entranceFlowPosDistriSuccess.descriptionHere") }}</a>.
    </v-card-text>
    <v-card-actions>
      <v-row no-gutters justify="center">
        <v-btn
          id="btn-finish-entrance"
          plain
          color="primary"
          class="font-weight-medium text-uppercase"
          @click="close"
        >
          {{ $t("components.broadcastTypes.entranceFlowPosDistriSuccess.doneBtn") }}
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<style scoped></style>

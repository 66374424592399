<script setup lang="ts">
import { MessageType } from "~/additionalModels/MessageType";

const emit = defineEmits(["open"]);

const { t } = useI18n();

const userModule = useUserModule();

const { asVendorCustomer, isOnboarded: isOnboardingComplete, isActivated, isNewPricingAvailable } = storeToRefs(userModule);

interface ShowOnEveryRoute {
  persist: boolean;
  url?: string;
  show?: (val: boolean) => void;
}

const showMessageField = ref(false);
const msgType = ref(MessageType.ALERT);
const msgTitle = ref("");
const msgContent = ref("");
const msgContentEx = ref("");
const showOnEveryRoute = ref<ShowOnEveryRoute>({ persist: false });

const isAMerchant = computed(() => asVendorCustomer.value.segment === "A" || asVendorCustomer.value.segment === "B");

const posCustomerEmail = computed(() => asVendorCustomer.value.linkedPosCustomer.contactPersons![0]?.eMail || null);

const messageClass = computed(() => {
  const type
      = msgType.value === MessageType.WARNING
        ? "messageField__warning"
        : "messageField__alert";
  const msg = showMessageField.value ? "messageField__visible" : "";

  return `${msg} ${type}`;
});

watch(isOnboardingComplete, (onboardingComplete) => {
  if (!onboardingComplete && !isActivated.value) {
    open();
    showMessageField.value = true;
    msgTitle.value = t(
      "onboardingV2.isBookingAvailable.title",
    ) as string;
    msgContent.value = t(
      "onboardingV2.isBookingAvailable.content",
    ) as string;
    showOnEveryRoute.value = { persist: true };
    msgType.value = MessageType.ALERT;
  }
}, { immediate: true });

watch(isNewPricingAvailable, (pricingAvailable: boolean) => {
  onIsNewPricingAvailableChange(pricingAvailable);
}, { immediate: true });

function onIsNewPricingAvailableChange(pricingAvailable: boolean) {
  if (pricingAvailable && isActivated.value && !isAMerchant.value) {
    open();
    msgTitle.value = t(
      "pages.packages.newPricingAvailable.title",
    ) as string;
    msgContent.value = t(
      "pages.packages.newPricingAvailable.content",
    ) as string;
    showOnEveryRoute.value = {
      persist: false,
      url: "/packages",
      show: onIsNewPricingAvailableChange,
    };
    msgType.value = MessageType.WARNING;
  }
}

watch(isActivated, (activated: boolean) => {
  if (!activated && isOnboardingComplete.value) {
    open();
    msgTitle.value = t("onboardingV2.isNotActivated.title") as string;
    msgContent.value = t(
      "onboardingV2.isNotActivated.content",
    ) as string;
    msgContentEx.value = t("onboardingV2.isNotActivated.contentEx", {
      PosUrl: posCustomerEmail.value,
    }) as string;
    showOnEveryRoute.value = { persist: true };
    msgType.value = MessageType.WARNING;
  }
}, { immediate: true });

watch(useRoute(), (route: any) => {
  if (!showOnEveryRoute.value.persist)
    close();

  if (route.path === showOnEveryRoute.value.url)
    showOnEveryRoute.value.show!(true);
}, { immediate: true });

function open() {
  showMessageField.value = true;
  emit("open", true);
}
function close() {
  showMessageField.value = false;
  emit("open", false);
}
</script>

<template>
  <v-row
    v-if="showMessageField"
    no-gutters
    class="d-flex flex-grow-1 flex-nowrap body-medium px-4 pt-2"
    :class="messageClass"
  >
    <div
      id="message"
      class="body-medium"
    >
      <div class="font-weight-bold">
        {{ msgTitle }}
      </div>
      <div>
        {{ msgContent }} <span
          v-if="posCustomerEmail"
          v-html="msgContentEx"
        />
      </div>
    </div>
    <notification-center class="ml-auto notification-center" />
  </v-row>
</template>

<style></style>

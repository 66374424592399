<script setup lang="ts">
const emit = defineEmits(["onLinkClick"]);

const { t } = useI18n();

const storeModule = useStoreModule();
const userModule = useUserModule();

const { isOnboarded: isOnboardingComplete, isActivated, isAllProfileComplete: isProfileComplete } = storeToRefs(userModule);
const { hasStores: isStoresComplete } = storeToRefs(storeModule);

const listItems = computed(() => [
  {
    label: t("onboardingV2.steps.profile"),
    link: "/profile",
    active: !isProfileComplete.value,

  },
  {
    label: t("onboardingV2.steps.stores"),
    link: "/store-management",
    active: !isStoresComplete.value,
  },
  {
    label: t("onboardingV2.steps.packages"),
    link: "/packages",
    active: !isOnboardingComplete.value,
  },
  {
    label: t("onboardingV2.steps.register"),
    link: null,
    active: !isActivated.value,
  },
]);

function handleLinkClick() {
  emit("onLinkClick");
}

function activeClass(active: boolean) {
  return active ? "activeClass" : "";
}
</script>

<template>
  <div>
    <div class="headline-small mb-2">
      Onboarding
    </div>
    <div class="title-small mb-4">
      {{ $t("onboardingV2.onboardingIndicatorList.sublineFourSteps") }}
    </div>

    <ul>
      <li
        v-for="(item, index) in listItems"
        :key="index"
        class="mb-4"
        :class="activeClass(!item.active)"
        @click="handleLinkClick()"
      >
        <!-- label clickable link -->
        <NuxtLink
          v-if="item.link"
          :to="item.link"
        >
          <span class="index ">{{ index + 1 }}</span>{{ item.label }}
        </NuxtLink>
        <!-- label only -->
        <div v-else>
          <span class="index ">{{ index + 1 }}</span>{{ item.label }}
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped>
  ul{
    list-style: none;
    padding:0;
  }
  li{
    display:flex;
    font-size: 1.063em !important;
    /* margin-bottom:1.5em; */
    padding:0;

  }

  li a{
    color:black !important;
    text-decoration: none;
  }

  .activeClass .index{
    background-color: #1fc795;
  }
  .index{
    margin-right:8px;
    border-radius:100%;
    width:20px;
    height:20px;
    line-height:20px;
    color:#fff;
    background-color: red;
    font-size: .825em !important;
    display:inline-block;
    text-align: center;

  }
</style>

<script setup lang="ts">
const broadcastModule = useBroadcastModule();

function close() {
  broadcastModule.close();
}
</script>

<template>
  <v-card id="card-onboarding-link-success" light max-width="400">
    <v-card-title>{{ $t("components.broadcastTypes.onboardingLinkSuccess.title") }}</v-card-title>
    <v-card-text>
      {{ $t("components.broadcastTypes.onboardingLinkSuccess.description") }}
      <nuxt-link to="/customer-management" @click="close">
        {{ $t("components.broadcastTypes.onboardingLinkSuccess.descriptionTarget") }}
      </nuxt-link>.
    </v-card-text>
    <v-card-actions>
      <v-row no-gutters justify="center">
        <v-btn id="btn-finish-entrance" plain @click="close">
          {{ $t("components.broadcastTypes.onboardingLinkSuccess.doneBtn") }}
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";

const { t } = useI18n();

useHead({
  script: [{ "src": "https://app.usercentrics.eu/browser-ui/latest/bundle.js", "id": "usercentrics-cmp", "data-settings-id": "AdywwghUp" }],
});

useSeoMeta({
  title: t("seo.title"),
  description: t("seo.description"),
});

const viewsWithoutNavigation = [
  "activation",
  "login",
  "entrance-flow-pos-dist",
  "privacy",
  "terms-of-service",

];

const authModule = useAuthModule();
const { authenticated } = storeToRefs(authModule);
const userModule = useUserModule();

const { isActivated, isVendorCustomer, loading, isParentVendorCustomer } = storeToRefs(userModule);

const drawer = ref(true);
const messageIsOpen = ref(false);
const open = ref(false);
let timeoutID: NodeJS.Timeout;

const showNavigationDrawer = computed(
  () => {
    const routeIsExcluded = viewsWithoutNavigation.some((view) => {
      return useRoute().path.includes(view);
    });
    return !routeIsExcluded && authenticated.value;
  },
);

const hideLangSwitch = computed(() =>
  useRoute().path.split("/").includes("entrance-flow-pos-dist"),
);

const appbarHeight = computed(() => {
  if (!showNavigationDrawer.value) { return; }
  return messageIsOpen.value ? 110 : 64;
});

onMounted(() => {

});

// open onboardingDialog programmatically after login
watch(
  isActivated,
  (active: boolean) => {
    if (!active) {
      timeoutID = setTimeout(() => {
        open.value = true;
      }, 2000);
    }
  },
  { immediate: true },
);

function close() {
  clearTimeout(timeoutID);
  open.value = false;
}

function handleMsgOpen(val: boolean) {
  messageIsOpen.value = val;
}
</script>

<template>
  <v-app
    v-if="!loading"
    style="background: rgba(0, 0, 0, 0)"
  >
    <ClientOnly
      fallback-tag="span"
    >
      <template #fallback>
        <div class="page">
          <div class="content">
            <v-col>
              <v-img
                src="/public/anybill-logo.svg"
                class="error-logo ma-4"
              />
              Partner Portal
            </v-col>
            <v-col>
              <v-progress-circular
                indeterminate
                class="mt-4"
              />
            </v-col>
          </div>
        </div>
      </template>
      <navigation-drawer
        v-if="showNavigationDrawer"
        v-model:drawer="drawer"
      />

      <v-app-bar
        :height="appbarHeight"
        style="background: transparent;"
        :order="showNavigationDrawer ? 1 : 0"
        absolute
        :elevation="showNavigationDrawer ? 0 : 1"
      >
        <!-- appbar is not logged in -->
        <v-container v-if="showNavigationDrawer">
          <v-row
            no-gutters
            align="center"
            class="pa-2 flex-nowrap"
          >
            <div class="navIcon__wrapper">
              <v-app-bar-nav-icon
                style="width: 28px; height: 28px"
                @click="drawer = !drawer"
              />
            </div>
            <!-- hide notification btn when message field is open -->
            <message-field
              v-if="isVendorCustomer && !isParentVendorCustomer!"
              @open="handleMsgOpen"
            />
          </v-row>
        </v-container>
        <!-- appbar is logged in -->
        <v-container
          v-if="!showNavigationDrawer"
          fluid
        >
          <v-row
            no-gutters
            justify="space-between"
            align="center"
            class="flex-wrap px-4"
          >
            <a
              href="https://anybill.de"
              target="_blank"
              rel="noreferrer noopener"
            >

              <v-img
                src="/anybill-logo.svg"
                contain
                style="width: 100px; height: 48px"
                class="mr-2"
              />
            </a>

            <v-row
              no-gutters
              justify="end"
              class="mr-3"
            >
              <div class="d-none d-sm-flex">
                <contact-form show-activator />
              </div>

              <language-settings
                v-if="!hideLangSwitch"
                :show-full-name="false"
                class="appBar__langSetting"
              />
            </v-row>
          </v-row>
        </v-container>
      </v-app-bar>

      <!-- <v-img
        v-if="!hideLangSwitch"
        src="/anybill-logo.svg"
        width="148px"
        height="27px"
        class="portalicon mr-5 mb-5"
      /> -->

      <v-main>
        <snack-bar />
        <broadcast-overlay />

        <nuxt-page style="z-index: 4" />

        <!-- Onboarding dialog -->
        <base-dialog
          v-if="isVendorCustomer && !isActivated && !isParentVendorCustomer"
          v-model:open="open"
          :width="400"
          :hide-actions="true"
          :hide-save="true"
          :set-full-height="false"
        >
          <template #title>
            {{ $t("onboardingV2.onboardingIndicatorList.head") }}
          </template>
          <template #subTitle>
            {{ $t("onboardingV2.onboardingIndicatorList.text") }}
          </template>
          <template #content>
            <onboarding-indicator-list @on-link-click="close()" />
          </template>
        </base-dialog>
      </v-main>
    </ClientOnly>
  </v-app>
</template>

<style lang="scss">
.messageField__visible {
  border-radius: 15px;
  padding: 8px 0px 8px 12px;
}

.messageField__alert {
  border: 2px solid red;
}

.messageField__warning {
  border: 2px solid #ffc56d;
}

.portalicon {
  position: absolute;
  bottom: 0;
  right: 0;
}

.notification-center .button {
  margin-left: auto !important;
}

.navIcon__wrapper {
  border: 1px solid black;
  border-radius: 50%;
  display: inline-block;
  margin-right:10px;
  background-color: white;
}

.v-btn>.v-btn__content .v-icon {
  width: 20px;
  height: 20px;
  font-size: 18px;
}

.heidelpaySandboxNotify {
  display: none !important;
}

.v-text-field input {
  font-weight: normal;
}

.v-data-table th {
  font-weight: normal;
  font-size: 1em;
}

.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content {
  opacity: 1 !important;
}

/* footer */

tr {
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 10px;
}

.page {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  text-align: center;
  max-width: 500px;
  width: 80%;
  padding: 50px 0;
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  /* Soft shadow */
}
</style>

<script setup lang="ts">
import { useTheme } from "vuetify";
import Lumifier from "~/utils/Lumifier";
import type Notification from "~/additionalModels/Notification";

const notificationModule = useNotificationModule();
const userModule = useUserModule();

const { notifications } = storeToRefs(notificationModule);
const { isActivated, isVendorCustomer } = storeToRefs(userModule);

const { current } = useTheme();

const centerOpen = ref(false);

const primaryColor = computed(() => current.value.colors.primary);
const secondaryColor = computed(() => current.value.colors.secondary);

const iconColorStyle = computed(() => {
  const l = Lumifier.calcLuma(primaryColor.value ?? "#FFFFFF");
  return `color: hsl(0, 0%, calc((${l} - 0.6) * -10000000%));`;
});

const isNotCompleteOnboarded = computed(() => !isActivated.value);

const notificationActive = computed(() => notifications.value.length || isNotCompleteOnboarded.value);

function removeNotification(index: number) {
  notificationModule.removeNotification(index);
}

function execAction(notif: Notification) {
  if (notif.action) {
    notif.action();
    centerOpen.value = false;
  }
}
</script>

<template>
  <v-menu v-model="centerOpen" offset-y left :close-on-content-click="false">
    <template #activator="{ props }">
      <v-btn
        class="btn-notification ml-auto"
        elevation="2"
        color="white"
        v-bind="props"
      >
        <svg-icon name="bell" width="24px" height="24px" />
        <div
          v-if="notificationActive"
          class="notif-wobble"
        />
      </v-btn>
    </template>

    <!-- onboarding list -->
    <v-card v-if="isNotCompleteOnboarded && isVendorCustomer" width="400">
      <v-card-text>
        <onboarding-indicator-list />
      </v-card-text>
    </v-card>

    <!-- notifications -->
    <v-card v-else width="400">
      <v-card-title> Notification Center </v-card-title>

      <v-card-text>
        <template v-if="notifications.length === 0">
          {{ $t("components.notificationCenter.allDone") }}
        </template>
        <template v-else>
          <v-card
            v-for="(notif, i) in notifications"
            :key="i"
            class="tw-mb-4"
            @click="execAction(notif)"
          >
            <v-row no-gutters>
              <v-img
                v-if="notif.image"
                :src="notif.image"
                contain
                max-width="100"
              />
              <v-col>
                <v-card-subtitle>
                  <v-row
                    no-gutters
                    align="center"
                    justify="space-between"
                    style="flex-wrap: nowrap !important"
                  >
                    <span style="font-weight: 500">{{ notif.title }}</span>
                    <XIcon @click="removeNotification(i)" />
                  </v-row>
                </v-card-subtitle>

                <v-card-text
                  v-if="notif.description.includes('/>')"
                  v-html="notif.description"
                />
                <v-card-text v-else>
                  {{ notif.description }}
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<style scoped>
.btn-notification {
  border-radius: 28px !important;
}
.notif-wobble {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 999px;
  top: 1px;
  right: 9px;
  background-color:red;
}
</style>

export default class Lumifier {
  static calcLuma(hexColor: string) {
    if (!hexColor)
      return 0;
    hexColor = hexColor.replace("#", "");
    // handle possible color variations
    if (hexColor.length < 6) {
      if (hexColor.length === 3) {
        hexColor
                    = hexColor[0]
                    + hexColor[0]
                    + hexColor[1]
                    + hexColor[1]
                    + hexColor[2]
                    + hexColor[2];
      }
      else {
        return 0;
      }
    }
    else if (hexColor.length > 6) {
      hexColor = hexColor.slice(2, 8);
    }
    const r = Number.parseInt(hexColor.slice(0, 2), 16);
    const g = Number.parseInt(hexColor.slice(2, 4), 16);
    const b = Number.parseInt(hexColor.slice(4, 6), 16);
    return (0.299 * r + 0.587 * g + 0.144 * b) / 255;
  }

  static lumifiedTextColor(color: string) {
    const l = Lumifier.calcLuma(color);
    return `color: hsl(0, 0%, calc((${l} - 0.6) * -10000000%));`;
  }
}

export enum MessageType {
  WARNING,
  ALERT,
}

export interface IMessage {
  msg: IMsgContent;
  type: MessageType;
}

export interface IMsgContent {
  head: string;
  content: string;
}

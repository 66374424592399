<script setup lang="ts">
const userModule = useUserModule();

const { asVendorCustomer: user } = storeToRefs(userModule);

const posDistriName = computed(() => {
  const distriName = user.value?.linkedDistributorCustomer?.name;
  if (distriName
    && user.value?.linkedDistributorCustomer?.contactPersons
    && user.value?.linkedDistributorCustomer?.contactPersons.length > 0)
    return distriName;
  else
    return user.value?.linkedPosCustomer?.name ?? "";
});

const contactPersons = computed(() => {
  const distriContacts = user.value?.linkedDistributorCustomer?.contactPersons;

  if (distriContacts && distriContacts?.length > 0) {
    return distriContacts;
  }
  else {
    const posContacts = user.value?.linkedPosCustomer?.contactPersons;
    return posContacts ?? [];
  }
});
</script>

<template>
  <v-card id="card-onboarding-success" light max-width="400">
    <v-card-title>{{ $t("components.broadcastTypes.entranceFlowSuccessMessage.title") }}</v-card-title>
    <v-card-text>
      <span v-html="$t('components.broadcastTypes.entranceFlowSuccessMessage.descriptionPartOne', { pos: posDistriName })" />
      <b>{{ posDistriName }}</b>{{ contactPersons.length > 0 ? " " : "." }}
      <span v-if="contactPersons.length > 0">{{ $t("components.broadcastTypes.entranceFlowSuccessMessage.descriptionUnder") }}
        {{
          contactPersons.length === 1
            ? $t("components.broadcastTypes.entranceFlowSuccessMessage.phoneNumber")
            : $t("components.broadcastTypes.entranceFlowSuccessMessage.phoneNumberPlural")
        }}:
        <div
          v-for="contact in contactPersons"
          :key="contact.eMail"
          class="tw-flex tw-flex-col"
        >
          <div class="tw-mt-2">E-Mail: {{ contact.eMail }}</div>
          <div>Tel.: {{ contact.phone }}</div>
        </div>
      </span>
      <br>
    </v-card-text>
    <v-card-actions>
      <v-row no-gutters justify="center">
        <v-btn id="btn-finish-entrance" plain href="/">
          {{ $t("components.broadcastTypes.entranceFlowSuccessMessage.doneBtn") }}
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<style scoped></style>

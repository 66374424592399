<script setup lang="ts">
import type { Cropper } from "vue-advanced-cropper";
import { SignalDispatcher } from "ste-signals";

const cropModule = useCropModule();
const broadcastModule = useBroadcastModule();

const _croppedDispatcher: SignalDispatcher = new SignalDispatcher();

const cropper = ref<typeof Cropper>();

const { toManipulate } = storeToRefs(cropModule);
const { args } = storeToRefs(broadcastModule);

const wantedAspectRatio = computed(() => args.value.ratio ?? 2.0);

const stencilSize = computed(() => ({
  width: wantedAspectRatio.value > 1 ? 500 : 250,
  height: 250,
}));

const onCropped = computed(() => _croppedDispatcher.asEvent());

function abort() {
  cropModule.reset();
  broadcastModule.close();
}

function crop() {
  const { canvas } = cropper.value?.getResult();
  broadcastModule.close();
  if (toManipulate.value)
    URL.revokeObjectURL(toManipulate.value);
  cropModule.manipulate(canvas?.toDataURL() ?? ""); // empty string for unit tests
  _croppedDispatcher.dispatchAsync();
}

function zoom(zoomfactor: number) {
  cropper.value?.zoom(zoomfactor);
}
</script>

<template>
  <v-card width="800" light>
    <v-card-title> {{ $t("components.broadcastTypes.cropRequired.title") }} </v-card-title>
    <v-card-subtitle>
      <span v-html="$t('components.broadcastTypes.cropRequired.description', { ratio: wantedAspectRatio > 1 ? '2:1' : '1:1' })" />
    </v-card-subtitle>
    <v-card-text>
      <div
        style="
          border-radius: 28px;
          overflow: hidden;
          background-clip: content-box;
          max-width: 750px;
          min-height: 375px;"
      >
        <cropper
          id="cropper"
          ref="cropper"
          :src="toManipulate"
          class="cropper"
          foreground-class="cropper-foreground"
          background-class="cropper-background"
          :stencil-props="{
            handlers: {},
            movable: false,
            scalable: false,
          }"
          :resize-image="{
            adjustStencil: false,
          }"
          :stencil-size="stencilSize"
          image-restriction="stencil"
        />
      </div>
      <div class="d-flex justify-center">
        <div class="ma-1">
          <v-btn id="btn-+" @click="zoom(1.1)">
            <PlusIcon />
          </v-btn>
        </div>
        <div class="ma-1">
          <v-btn id="btn--" @click="zoom(0.9)">
            <MinusIcon />
          </v-btn>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-row no-gutters justify="end">
        <v-btn id="btn-abort" depressed @click="abort">
          {{ $t("components.broadcastTypes.cropRequired.abortBtn") }}
        </v-btn>
        <v-btn id="btn-crop" color="primary" depressed @click="crop">
          {{ $t("components.broadcastTypes.cropRequired.cropBtn") }}
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss">
@import "vue-advanced-cropper/dist/style.css";

.cropper {
  height: 375px;
  width: 750px;
  overflow: hidden;
  background-clip: content-box;
  padding: 1px;
}

.cropper-background {
  border-radius: 28px;
  background: #c4c4c4;
}

.cropper-foreground {
  border-radius: 28px;
}
</style>
